@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.footer {
  background-color: #fae5b9;
  width: 100vw;
  position: fixed;
  bottom: 0rem;
  font-weight: 400;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: right;
  height: 20px;

  @include tablet {
    height: 40px;
  }

  &__image {
    height: 20px;
    width: 30px;
    margin-right: 2rem;

    @include tablet {
      height: 40px;
      width: 50px;
      margin-right: 0rem;
    }
  }
}
