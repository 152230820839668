@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.projects {
  background-color: #f8f2e6;
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.25rem;
  padding-bottom: 1rem;

  @include tablet {
    padding-left: 6rem;
    padding-right: 8rem;
    padding-top: 0.5rem;
    padding-bottom: 3rem;
  }

  @include desktop {
    padding-left: 8rem;
    padding-right: 10rem;
    padding-top: 1rem;
    padding-bottom: 5rem;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__heading {
    font-family: InterRegular;
    padding-bottom: 0.5rem;
    text-align: center;
    font-size: 1.5rem;

    @include tablet {
      font-size: 2rem;
    }
  }

  &__heading--project {
    padding-top: 1rem;

    @include desktop {
      padding-top: 5rem;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    padding-top: 1rem;
    gap: 1rem;
    max-width: 70vw;

    @include tablet {
      max-width: 75vw;
    }

    @include desktop {
      font-size: 1rem;
      max-width: 80vw;
    }
  }

  &__highlight {
    font-weight: 600;
    color: #f78352;
  }
  &__links {
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1rem;
    font-style: italic;
  }

  &__languages {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding-top: 2rem;
    padding-bottom: 4rem;
    margin: auto;
    max-width: 18.75rem;

    @include tablet {
      max-width: 31.25rem;
    }

    @include desktop {
      max-width: 40rem;
    }
  }

  &__skill {
    margin: auto;
    width: 1.875rem;

    @include tablet {
      width: 5rem;
    }

    @include desktop {
      width: 6.25rem;
    }
  }

  &__image {
    width: 18.75rem;

    @include tablet {
      width: 25rem;
    }
    @include desktop {
      width: 37.5rem;
    }
  }
}

.medisca {
  display: flex;
  flex-direction: column;

  &__images {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
  &__preview {
    border-radius: 0.5rem;
    width: 21.87rem;
    margin: 0.5rem;

    @include tablet {
      width: 37.5rem;
      margin: 1rem;
    }
    @include desktop {
      width: 50rem;
      margin: 2rem;
    }
  }
}

.capstone {
  &__preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__preview--dashboard {
    border-radius: 0.5rem;
    width: 21.87rem;
    margin: 0.5rem;

    @include tablet {
      width: 37.5rem;
      margin: 1rem;
    }
    @include desktop {
      width: 50rem;
      margin: 2rem;
    }
  }

  &__preview--payment {
    margin: 0.5rem;
    border-radius: 0.5rem;
    width: 21.87rem;

    @include tablet {
      width: 37.5rem;
    }

    @include desktop {
      width: 50rem;
      margin: 2rem;
    }
  }
}

.brainflix {
  &__preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include tablet {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  &__preview--1 {
    border-radius: 0.5rem;
    width: 21.87rem;
    margin: 0.5rem;

    @include tablet {
      width: 37.5rem;
      margin: 1rem;
    }

    @include desktop {
      width: 50rem;
      margin: 2rem;
    }
  }

  &__preview--3 {
    border-radius: 0.5rem;
    width: 21.87rem;
    height: 15.65rem;
    margin: 0.5rem;

    @include tablet {
      width: 37.5rem;
      height: 25rem;
      margin: 1rem;
    }

    @include desktop {
      width: 50rem;
      height: 31.25rem;
      margin: 2rem;
    }
  }
}

.pokedex {
  &__preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__preview--image {
    border: 0.031rem black solid;
    border-radius: 1rem;
    width: 21.87rem;
    margin: 0.5rem;

    @include tablet {
      width: 37.5rem;
      margin: 0.5rem;
    }
    @include desktop {
      width: 50rem;
      margin: 1rem;
    }
  }

  &__preview--lastimage {
    border: 0.031rem black solid;
    border-radius: 1rem;
    margin-bottom: 2rem;
    width: 21.87rem;
    margin: 0.5rem;

    @include tablet {
      width: 37.5rem;
      margin: 0.5rem;
    }
    @include desktop {
      width: 50rem;
      margin: 1rem;
    }
  }
}
