@use "./styles/partials/mixins" as *;
@use "./styles/partials/typography" as *;
@use "./styles/partials/variables" as *;

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  color: black;
  font-family: "InterRegular";
  background-color: #f8f2e6;
}
a:link,
a:visited {
  color: black;
  text-decoration: none;
}
a:hover,
a:active {
  color: black;
  text-decoration: none;
}
h2 {
  font-size: 1rem;

  @include tablet {
    font-size: 1rem;
  }

  @include desktop {
    font-size: 1.25rem;
  }
}
h3 {
  font-size: 1rem;
}
input,
textarea {
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid #ccc;
}
.app {
  width: 75%;
  margin: 0;
  padding-bottom: 2rem;
  background-color: #f8f2e6;
}
