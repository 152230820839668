@font-face {
  font-family: DM Sans;
  src: url("../../assets/fonts/DMSans-Regular.ttf");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: DM Sans;
  src: url("../../assets/fonts/DMSans-Medium.ttf");
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: Glacial Indifference;
  src: url("../../assets/fonts/GlacialIndifference-Regular.otf");
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: InterExtraLight;
  src: url("../../assets/fonts/Inter-ExtraLight.ttf");
  font-style: normal;
  // font-weight: 400;
}

@font-face {
  font-family: InterThin;
  src: url("../../assets/fonts/Inter-Thin.ttf");
  font-style: normal;
  // font-weight: 400;
}

@font-face {
  font-family: InterRegular;
  src: url("../../assets/fonts/Inter-Regular.ttf");
  font-style: normal;
  // font-weight: 400;
}

@font-face {
  font-family: InterBold;
  src: url("../../assets/fonts/Inter-Bold.ttf");
  font-style: normal;
  // font-weight: 400;
}
