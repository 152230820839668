@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.header {
  background-color: #f8f2e6;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet {
    display: flex;
    flex-direction: row;
    align-items: none;
  }

  &__logo {
    width: 300px;
  }

  &__list {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    gap: 2rem;
    font-size: 0.75rem;

    @include tablet {
      padding: 5rem;
      gap: 2.5rem;
      font-size: 1rem;
    }

    @include desktop {
      padding: 5rem;
      gap: 5rem;
      margin-left: 20%;
      font-size: 1.25rem;
    }
  }
}

a:hover {
  color: #404040;
}

a.active {
  font-weight: 600;
}
