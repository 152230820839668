@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.home {
  background-color: #f8f2e6;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  padding: 2rem;
  padding-bottom: 1rem;

  @include tablet {
    flex-direction: row;
    gap: 5rem;
    padding: 2rem;
    padding-bottom: 1rem;
  }

  @include desktop {
    gap: 8rem;
    padding: 4rem;
    padding-bottom: 1rem;
  }

  &__section--container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &__name {
    font-size: 0.6rem;
    font-family: "InterRegular";
    font-style: italic;
    font-weight: 400;
    margin: auto;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 80vw;

    @include tablet {
      font-size: 0.75rem;
      max-width: 65vw;
    }

    @include desktop {
      font-size: 0.9rem;
      max-width: 50vw;
    }
  }

  &__intro {
    font-family: "InterRegular";
    font-weight: 400;
    font-size: 1.5rem;

    @include tablet {
      font-size: 2rem;
    }
    @include desktop {
      font-size: 3rem;
    }
  }

  &__role {
    font-family: "InterRegular";
    color: #f78352;
    font-weight: 800;
    font-size: 2rem;

    @include tablet {
      font-size: 3rem;
    }

    @include desktop {
      font-size: 5rem;
    }
  }

  &__image {
    display: none;
    width: 150px;
    margin-bottom: 2rem;
    border: round;
    border-radius: 0.5rem;

    @include tablet {
      display: block;
      width: 200px;
      margin-bottom: 2rem;
    }

    @include desktop {
      width: 275px;
      margin-bottom: 3rem;
    }
  }

  &__accent {
    font-size: 1rem;
    color: #f78352;

    @include tablet {
      font-size: 1.5rem;
    }
  }
  &__icon {
    width: 15px;

    @include tablet {
      width: 30px;
    }
  }

  &__social {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f2e6;
    gap: 1rem;
    padding-top: 1rem;
  }

  &__divider {
    border: 0.1rem #f8ceb1 solid;
    margin: 0 rem 2rem;

    @include desktop {
      margin: 0rem 4rem;
    }
  }
  &__bio {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    padding-bottom: 1rem;
    margin: auto;
    gap: 2rem;

    @include tablet {
      padding: 2rem;
      padding-bottom: 1rem;
    }

    @include desktop {
      padding: 4rem;
      padding-bottom: 1rem;
    }
  }

  &__card {
    display: flex;
    flex-direction: row;
    gap: 8rem;
    align-items: center;
    margin: 0rem 5rem;
    font-size: 0.75rem;

    @include tablet {
      font-size: 1rem;
    }

    @include desktop {
      font-size: 1.25rem;
    }
  }
}
