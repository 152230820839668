@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.skills {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #f8f2e5;
    max-width: 18.75rem;
    margin: auto;
    gap: 1rem;

    @include tablet {
      max-width: 31.25rem;
    }

    @include desktop {
      max-width: 50rem;
    }
  }

  &__image {
    margin: auto;
    width: 50px;

    @include tablet {
      width: 100px;
    }

    @include desktop {
      width: 150px;
    }
  }
}
